<template>
  <div class="PersonalDocumentacion">
    <v-data-table
      class="upper"
      :headers="headers"
      :items="documentos"
      :loading="cargando"
      loading-text="Cargando..."
    >
      <template v-slot:item.actions="{ item }">
        <v-tooltip
          bottom
          v-if="!item.documento_pdo"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="fileToUpload = Object.assign({},item);showDialogFiles=true"
            >
              <v-icon>upload</v-icon>
            </v-btn>
          </template>
          <span>CARGAR {{item.titulo_doc}}</span>
        </v-tooltip>
        <v-btn
          v-else
          icon
          v-bind="attrs"
          v-on="on"
          :href="$files+item.documento_pdo"
          target="_blank"
        >
          <v-icon>panorama</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog
      v-model="showDialogFiles"
      persistent
      width="400"
    >
      <v-card
        :loading="cargando"
        :disabled="cargando"
      >
        <v-card-title>
          Subir &nbsp;<span style="text-transform: capitalize;">{{fileToUpload.titulo_doc?fileToUpload.titulo_doc.toLowerCase():''}}</span>
        </v-card-title>
        <v-card-text>
          <v-file-input
            :accept="showDialogFiles ? acceptOptions[fileToUpload.tipo_doc] : accept"
            filled
            label="Seleccionar archivo"
            :hint="fileToUpload.notas_doc"
            persistent-hint
            v-model="fileSelected"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="showDialogFiles=false;fileToUpload=Object"
            text
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            @click="_doUploadFile"
            :disabled="!fileSelected"
            :loading="cargando"
          >
            Guardar archivo
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import forms from "@/mixins/forms";
export default {
  name: "PersonalDocumentacion",
  mixins: [forms],
  data() {
    return {
      ruta: "personal/documentos",
      documentos: [],
      headers: [
        { text: "Documento", value: "titulo_doc" },
        { text: "", value: "actions", align: "end" },
      ],
      fileToUpload: {},
      showDialogFiles: false,
      accept: "application/xml,application/pdf,image/jpeg,image/png",
      acceptOptions: {
        image: "image/jpeg,image/png",
        pdf: "application/pdf",
        xml: "application/xml",
        all: "application/xml,application/pdf,image/jpeg,image/png",
      },
      fileSelected: null,
    };
  },
  methods: {
    _getDocumentos(res = null) {
      if (res) this.$emit("msg", res.msg);
      this.cargando = false;
      this.showDialogFiles = false;
      this.fileToUpload = {};
      this.fileSelected = null;
      this._getThings(this.ruta, "documentos");
    },
    async _doUploadFile() {
      this.cargando = true;
      try {
        var data = new FormData();
        data.append("file", this.fileSelected);
        data.append("id", this.fileToUpload.id);
        this._saveAll(this.ruta, data, "msg", "_getDocumentos");
      } catch (err) {
        this._handleError(err);
      }
    },
  },
  created() {
    this._getDocumentos();
  },
};
</script>